<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container-fluid">
                    <div class="grid justify-content-between">
                        <div class="col-12 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <Avatar size="large" class="primary" icon="pi pi-pencil" />
                                        </div>
                                        <div class="ml-2">
                                            <div class="text-2xl text-primary font-bold">Edit Requirement</div>
                                            <div class="text-sm text-500"> Modify minimum entry requirement record </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <hr />
            </template>
            <section class="page-section " >
                <div class="container-fluid">
                    <div class="grid ">
                        <div class="md:col-9 sm:col-12 comp-grid" >
                            <div class="">
                                <div >
                                    <template v-if="!loading">
                                        <div class="grid">
                                            <div class="col-12">
                                                <form ref="observer"  tag="form" @submit.prevent="startRecordUpdate()">
                                                    <!--[form-content-start]-->
                                                    <div class="grid">
                                                        <div class="col-12">
                                                            <div class="mb-2 font-bold text-sm">Title *</div>
                                                            <div id="ctrl-title-holder"> 
                                                                <InputText ref="ctrltitle" v-model.trim="formData.title"  label="Title" type="text" placeholder="Title"      
                                                                class=" w-full" :class="getErrorClass('title')">
                                                                </InputText>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="mb-2 font-bold text-sm">Description *</div>
                                                            <div id="ctrl-description-holder"> 
                                                                <div class="mb-3" :class="getErrorClass('description')">
                                                                    <Editor label="Description" ref="ctrldescription" placeholder="Description" v-model="formData.description" editorStyle="height: 180px"></Editor >
                                                                    <!-- <small class="p-3 text-negative" v-if="invalid && validated">{{errors[0]}}</small> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--[form-content-end]-->
                                                    <div v-if="showSubmitButton" class="text-center my-3">
                                                        <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                                    </div>
                                                </form>
                                                <slot :submit="submit" :saving="saving"></slot>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="p-3 text-center">
                                            <ProgressSpinner style="width:50px;height:50px" />
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>
<script>
		//[--PAGE-IMPORT-STATEMENT--]
	import { PageMixin } from "../../mixins/page.js";
	import { EditPageMixin } from "../../mixins/editpage.js";
	import { mapActions, mapGetters, mapState } from "vuex";
	import useVuelidate from '@vuelidate/core';
	import { required, email, sameAs, minLength,maxLength,minValue,maxValue,numeric,integer,decimal,ipAddress } from '@vuelidate/validators';
	export default {
		setup () {
			return { v$: useVuelidate() }
		},
		name: 'editMinimumentryrequirementsPage',
		components: {
			//[--PAGE-COMPONENT-NAME--]
		},
		mixins: [PageMixin, EditPageMixin ],
		props: {
			pageName: {
				type: String,
				default: 'minimum_entry_requirements',
			},
			idName: {
				type: String,
				default: 'minimum_entry_requirement_id',
			},
			routeName: {
				type: String,
				default: 'minimum_entry_requirementsedit',
			},
			pagePath: {
				type : String,
				default : 'minimum_entry_requirements/edit',
			},
			apiPath: {
				type: String,
				default: 'minimum_entry_requirements/edit',
			},
		},
		data() {
            return {
				formData: {
					title: "", description: "", 
				},
				submitted: false,
        	}
		},
		computed: {
			pageTitle:{
				get: function () {
					return "Edit Minimum Entry Requirements"
				}
			},
			currentRecord: {
				get: function () {
					return this.$store.getters["minimum_entry_requirements/currentRecord"];
				},
				set: function (value) {
					this.$store.commit("minimum_entry_requirements/setCurrentRecord", value);
				},
			},
		},
		validations() {
			let formData =  {
				title: { required },description: { required },
			}
			return {formData}
		},
		methods: {
			...mapActions('minimum_entry_requirements', ['updateRecord', 'fetchRecord']),
			async startRecordUpdate(){
				this.submitted = true;
				const isFormValid = !this.v$.$invalid;
				if(!isFormValid){
					this.flashMsg("Please complete the form", "Form is invalid", "error");
					return;
				}
				this.saving = true;
				let id = this.id;
				let url = this.apiUrl;
				let payload = this.normalizedFormData();
				let data = { id, url, payload }
				this.updateRecord(data).then(
					(response) => {
						this.saving = false;
						this.flashMsg(this.msgAfterUpdate);
						this.resetForm();
						this.closeDialogs();// close page dialog that if opened
						if(this.redirect) this.navigateTo(`/minimum_entry_requirements`);
					},
					(response) => {
						this.saving = false;
						this.showPageRequestError(response);
					}
				);
			},
			updateFormFields: function(){
				//update form fields value after load from api
				//e.g convert fieldvalue (value,value2,value2) to array 
            },
			resetForm (){
				//reset form fields value
				this.formData = {title: "", description: "", };
				//raise event to reset other custom form components
				//this.$EventBus.$emit("resetForm");
			},
			getErrorClass(field){
			   if(this.v$.formData[field].$invalid && this.submitted){
				    return "p-invalid";
			   }
               return "";
            },
		},
		watch: {
		},
		async mounted() {
		},
		async created() {
		},
	};
</script>
<style scoped>
</style>
<style scoped>

</style>
